@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css");
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply h-full bg-gray-100;
}

.input {
  @apply block placeholder-gray-400 border-0 rounded shadow focus:outline-none focus:ring-2 focus:ring-indigo-700 bg-gray-50;
}

.button-primary {
  @apply items-stretch py-2 text-white transition-all bg-indigo-900 rounded shadow hover:bg-indigo-700;
}

.toggle-checkbox:checked {
  @apply: right-0 border-indigo-800;
  right: 0;
  border-color: #3b82f6;
}

.toggle-checkbox:checked + .toggle-label {
  @apply: bg-indigo-800;
  background-color: #3b82f6;
}

.z-index {
  z-index: 0;
}

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 70; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
